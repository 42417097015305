body {
    background: url(https://storage.googleapis.com/ben-porayko/images/background-images/adrien-olichon-RCAhiGJsUUE-unsplash.jpg);
    background-attachment: fixed !important;
    background-size: cover !important;
    background-position: center top !important;
    font-family: 'Lato', sans-serif;
}

p {
    font-family: 'Lato', sans-serif;
}
hr {
    border-color: #ff2e62b9;
    border-style: dotted none none;
    border-width: 15px 0 0 0;
    width: 20%;
}
.card-img-top {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}