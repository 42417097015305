.title-text {
    color: #FF2E63;
}

.title-text-secondary {
    color: #EAEAEA;
}

.title-text-font {
    font-family: 'Abril Fatface', cursive;
}

.title-text-col {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10% !important;
    padding-left: 1% !important;
}

.blog-col {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.alt-head {
    text-align: right;
}

.info-text {
    font-size: large;
}

.skill-col {
    text-align: center;
}
.skill-icon {
    font-size: 100px;
}

.icon-link {
    text-decoration: none;
}

.aspect-ratio {
    position: relative;
    padding-bottom: 50%;
}